export function handRankKr(en) {
    switch (en) {
        case 'flush':
            return '플러시';
        case 'fourofakind':
            return '포 오브 어 카인드';
        case 'fullhouse':
            return '풀 하우스';
        case 'highcard':
            return '하이 카드';
        case 'onepair':
            return '원 페어';
        case 'royalflush':
            return '로열 플러시';
        case 'straightflush':
            return '스트레이트 플러시';
        case 'straight':
            return '스트레이트';
        case 'threeofakind':
            return '쓰리 오브 어 카인드';
        case 'twopairs':
            return '투 페어';
        default:
            return '';
    }
}