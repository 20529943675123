<script>
import {mapGetters, mapActions} from "vuex";
import {handRankKr} from "@/utils/translations";
import {safeNumberConversion} from "@/utils/tools";
import {debounce} from "@/utils/debounce";

export default {
  name: "HandHistoryDetailItem",
  props: {
    item: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      expand: false,
    }
  },
  computed: {
    ...mapGetters('user', ['gameWinners', 'current', 'handHistoryDetailsModal']),
    ...mapGetters('config', ['cardResourceNumber']),
    historyId() {
      return this.item.sn;
    },
    isCurrent() {
      return this.current === this.historyId;
    },
    uuid() {
      return this.item.game_uuid;
    },
    uniqueGameUUID() {
      return `${this.item.game_sn}${this.uuid}`;
    },
    isWin() {
      const {result: value} = this.item;
      return value;
    },
    hasFolded() {
      const {has_folded: value} = this.item;
      return value;
    },
    gameResult() {
      if (this.isWin) {
        return '승리';
      }

      return this.hasFolded ? '폴드' : '패배';
    },
    handRank() {
      const {hand_rank: value} = this.item;
      return this.getHandRankKR(value);
    },
    roundEndTime() {
      const {end_time: value} = this.item;
      const datetime = new Date(value);

      const h = datetime.getHours();
      const m = datetime.getMinutes();
      const s = datetime.getSeconds();
      return [h, m, s].map(t => t < 10 ? '0' + t : t).join(':');
    },
    netProfit() {
      const {net_profit: value} = this.item;
      return this.toSafeNumber(value);
    },
    hand() {
      return this.item.hand.split(',');
    },
    communityCards() {
      return this.item.community_card.split(',');
    },
    isBestHand() {
      return this.item && 'handDetails' in this.item;
    },
    /* eslint-disable no-unused-vars */
    highlightHandMap() {
      if (!this.isBestHand) {
        return {};
      }

      const {cards} = this.item.handDetails;

      return this.hand.concat(this.communityCards).reduce((highlightMap, hand) => {
        const [suit, nick] = hand.split('_');
        highlightMap[hand] = cards.indexOf(nick) !== -1;
        return highlightMap;
      }, {});
    },
    /* eslint-disable no-unused-vars */
    highlightHandMapForOtherWinner() {
      return this.gameWinners.reduce((highlightMaps, winner) => {
        if (!('handDetails' in winner)) {
          return highlightMaps;
        }

        const {sn, hand, handDetails} = winner;
        const allCards = hand.split(',').concat(this.communityCards);

        highlightMaps[sn] = allCards.reduce((highlightMap, hand) => {
          const [suit, nick] = hand.split('_');
          highlightMap[hand] = handDetails.cards.indexOf(nick) !== -1;
          return highlightMap;
        }, {});

        return highlightMaps;
      }, {})
    }
  },
  watch: {
    current() {
      this.expand = this.isCurrent && !this.expand;
    },
    // Modal View 전환 시 이전 화면 초기화
    handHistoryDetailsModal() {
      if (!this.handHistoryDetailsModal) {
        this.$store.commit('user/SET_CURRENT', 0);
        this.expand = false;
      }
    }
  },
  methods: {
    ...mapActions('user', {
      __fetchGameWinners: 'fetchGameWinners'
    }),
    getHandRankKR(name) {
      const value = (name.match(/[a-z]/gi) || []).join('').toLowerCase();
      return handRankKr(value);
    },
    toSafeNumber(value) {
      return safeNumberConversion(value || 0);
    },
    toCardImage(card) {
      try {
        let [suit, nick] = card.split('_');
        const rankAbbr = nick.charAt(0) || '';
        let rank = rankAbbr.toLowerCase() === 't' ? 10 : rankAbbr.toUpperCase();

        return require(`@/assets/v2/svg/deck${this.cardResourceNumber}/${suit.toLowerCase()}_${rank}.svg`);
      } catch (e) {
        return require('@/assets/v2/img/card_back_1.webp');
      }
    },
    getAvartarImage(avatarNumber) {
      avatarNumber = avatarNumber.match(/\d/gmi).join('');
      avatarNumber = parseInt(avatarNumber);

      return require(`@/assets/v2/img/avatar/avatar${avatarNumber < 10 ? '0' : ''}${avatarNumber}.jpg`);
    },
    async onClickItem() {
      if (this.isWin) {
        return;
      }

      if (this.expand) {
        this.$store.commit('user/SET_CURRENT', 0);
        this.expand = !this.expand;
        return;
      }

      this.emitFetchWinners();
    },
    emitFetchWinners: debounce(function () {
      const {sn, game_sn, game_uuid} = this.item;

      this.$store.commit('user/SET_CURRENT', sn);

      this.$emit('winners', {game_sn, game_uuid});
    }, 500, {leading: true}),
  }
}
</script>

<template>
  <div ref="root" class="item_detail" @click.prevent.stop="onClickItem">
    <div class="game_info" :class="{ win: isWin }">
      <div class="area_left">
        <div class="num_unique">#{{ uniqueGameUUID }}</div>
        <div class="outcome">{{ gameResult }}</div>
        <div v-if="isWin" class="txt_result">{{ handRank }}</div>
      </div>
      <div class="area_right">
        <div class="time_end">
          <div>핸드 종료시간</div>
          <div>{{ roundEndTime }}</div>
        </div>

        <div class="coin mb-2">
          <div>획득코인</div>
          <div class="v_coin v_num" :class="{ l_num: netProfit < 0 }">{{ netProfit | formatCash }}</div>
        </div>

        <div class="result_card">
          <ul class="list_card">
            <li
              :key="card"
              v-for="card in hand"
              :class="{
                active: isWin && highlightHandMap[card]
              }"
            >
              <img :src="toCardImage(card)"/>
            </li>
            <li
              :key="i"
              v-for="(card, i) in communityCards"
              :class="{
                active: isWin && highlightHandMap[card]
              }"
            >
              <img :src="toCardImage(card)">
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!-- 승자 정보 상세 -->
    <transition-group
      name="expand"
      tag="div"
      mode="in-out"
    >
      <template v-for="(winner, index) in gameWinners">
        <div
          v-if="expand"
          :key="index"
          ref="winners"
          class="winner_info"
        >
          <div class="area_left">
            <div class="area_profile">
              <div class="profile" data-bs-toggle="dropdown" aria-expanded="false">
                <div class="centered">
                  <img :src="getAvartarImage(winner.photo)" alt="">
                </div>
              </div>
            </div>
            <div class="name">{{ winner.name }}</div>
          </div>
          <div class="area_right">
            <div class="coin mb-2">
              <div>{{ getHandRankKR(winner.hand_rank) }}</div>
              <div class="v_num">승리 {{ toSafeNumber(winner.net_profit) | formatCash }}</div>
            </div>

            <!-- 승자 Hand -->
            <ul class="list_card">
              <li
                v-for="card in winner.hand.split(',')"
                :key="card"
                :class="{
                  active: highlightHandMapForOtherWinner[winner.sn] && (highlightHandMapForOtherWinner[winner.sn][card] || false)
                }"
              >
                <img :src="toCardImage(card)"/>
              </li>

              <!-- Community Card List -->
              <li
                v-for="(card, n) in communityCards"
                :key="n"
                :class="{
                  active: highlightHandMapForOtherWinner[winner.sn] && (highlightHandMapForOtherWinner[winner.sn][card] || false)
                }"
              >
                <img :src="toCardImage(card)">
              </li>
            </ul>
          </div>
        </div>
      </template>
    </transition-group>
  </div>
</template>

<style scoped lang="scss">

.expand-enter-active, .expand-leave-active {
  transition: .5s ease-in-out;
}

.expand-enter, .expand-leave-to
  /* .component-fade-leave-active below version 2.1.8 */
{
  transform: translateY(3rem);
  opacity: 0;
}
</style>