export function debounce(func, wait, options = {}) {
    let timeout;
    let args;
    let context;
    let result;
    let shouldCall = true;

    const later = function () {
        if (options.leading && shouldCall) {
            result = func.apply(context, args);
            shouldCall = false;
        }
        timeout = setTimeout(() => {
            timeout = null;
            shouldCall = true;
            if (!options.leading) {
                result = func.apply(context, args);
                context = args = null;
            }
        }, wait);
    };

    return function (...innerArgs) {
        context = this;
        args = innerArgs;
        if (!timeout) {
            if (options.leading && shouldCall) {
                result = func.apply(context, args);
                shouldCall = false;
            }
            later();
        }
        return result;
    };
}